<template>
	<div class="animated fadeIn">
		<b-card>
			<b-card-title><i class="fa fa-warning"></i> Incident Report</b-card-title>
			<b-card-sub-title>Summary of all reported incidents from dispatches</b-card-sub-title>
			<b-container fluid class="mt-4">
				<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

				<b-card>
					<b-row>
						<b-col md="12" sm="12" class="mt-1 mb-2">
							<b>FILTER OPTIONS</b>
						</b-col>
					</b-row>

					<!-- Date Range -->
					<b-row no-gutters>
						<b-col lg="4" md="5" sm="12" class="mr-2">
							<b-form-group label="Date From">
								<b-form-datepicker name="Date From" v-model="filterBy.dateFrom" locale="en" reset-button
									label-reset-button="Clear" :date-format-options="{
										year: 'numeric',
										month: 'short',
										day: '2-digit',
										weekday: 'short',
									}" :date-disabled-fn="dateFromDisabled" v-validate="'required'" />
								<span v-show="errors.has('Date From')" class="help-block">
									{{ errors.first('Date From') }}
								</span>
							</b-form-group>
						</b-col>
						<b-col lg="4" md="5" sm="12" class="mr-2">
							<b-form-group label="Date To">
								<b-form-datepicker name="Date To" v-model="filterBy.dateTo" locale="en" reset-button
									label-reset-button="Clear" :date-format-options="{
										year: 'numeric',
										month: 'short',
										day: '2-digit',
										weekday: 'short',
									}" :date-disabled-fn="dateFromDisabled" v-validate="'required'" />
								<span v-show="errors.has('Date To')" class="help-block">
									{{ errors.first('Date To') }}
								</span>
							</b-form-group>
						</b-col>
					</b-row>

					<!-- Dispatches -->
					<b-row no-gutters>

						<b-col lg="4" md="6" sm="12" class="mr-2">
							<b-form-group label="Incident Type">
								<v-select class="style-chooser" label="text" :options="incidentTypeOptions"
									:reduce="(incidentType) => incidentType.value" v-model="filterBy.incidentType">
									<template v-slot:no-options="{ search, searching }">
										<template v-if="searching">
											No results found for
											<em>
												<strong>{{ search }}</strong>
											</em>
										</template>
										<em :style="{ opacity: 0.5 }" v-else>
											Start typing to search for a plate number
										</em>
									</template>
								</v-select>
							</b-form-group>
						</b-col>
						<b-col lg="4" md="6" sm="12" class="mr-2">
							<b-form-group label="Dispatch ID">
								<b-form-input id="dispatchId" name="Dispatch ID" type="search" v-model="filterBy.dispatchId"
									v-validate="{ regex: /^([a-zA-Z0-9\-])*$/ }" />
								<span v-show="errors.has('Dispatch Id')" class="help-block">{{
									errors.first('Dispatch ID')
								}}</span>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row no-gutters>
						<b-col lg="4" md="6" sm="12" class="mr-2">
							<b-form-group label="Driver">
								<v-select class="style-chooser" label="text" :options="driverOptions"
									:reduce="(driver) => driver.value" v-model="filterBy.driver">
									<template v-slot:no-options="{ search, searching }">
										<template v-if="searching">
											No results found for
											<em>
												<strong>{{ search }}</strong>
											</em>
										</template>
										<em :style="{ opacity: 0.5 }" v-else>
											Start typing to search for a driver
										</em>
									</template>
								</v-select>
							</b-form-group>
						</b-col>

						<b-col lg="4" md="6" sm="12" class="mr-2">
							<b-form-group label="Incident ID">
								<b-form-input id="incidentId" name="Incident ID" type="search" v-model="filterBy.incidentId"
									v-validate="{ regex: /^([a-zA-Z0-9\-])*$/ }" />
								<span v-show="errors.has('Incident ID')" class="help-block">{{
									errors.first('Incident ID')
								}}</span>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row no-gutters>
						<b-col sm="12">
							<b-button class="mr-1" variant="success" @click="onFilterRequest">
								Generate
							</b-button>
							<b-button class="mr-1" variant="primary" @click="resetFilters">
								Reset
							</b-button>
						</b-col>
					</b-row>
				</b-card>

				<!-- Select Actions and Items Per Page Options -->
				<b-row>
					<b-col sm="6" md="3" class="mt-4 mb-2">
						<b-dropdown text=" Select Actions " variant="dark" slot="append">
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="xls" :name="fileName + '.xls'">
									Export Incident Report in Excel
								</json-excel>
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="csv" :name="fileName + '.csv'">
									Export Incident Report to CSV
								</json-excel>
							</b-dropdown-item>
						</b-dropdown>
					</b-col>

					<b-col sm="6" md="4" offset-md="5" class="mt-4 mb-2 text-md-right">
						<b-input-group prepend="Show" append="/ Page">
							<b-form-select :options="pageOptions" v-model="perPage" />
						</b-input-group>
					</b-col>
				</b-row>

				<b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
					:per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
					:sort-direction="sortDirection" responsive>
					<template v-slot:cell(source)="row">
						<span class="location-display">
							{{ row.item.source.company }}
							<br />
							({{ row.item.source.storageLocation }})
						</span>
					</template>

					<template v-slot:cell(destination)="row">
						<span class="location-display">
							{{ row.item.destination.company }}
							<br />
							({{ row.item.destination.storageLocation }})
						</span>
					</template>

					<template v-slot:cell(dateCreated)="row">{{
						row.item.dateCreated
						? getDisplayDateTime(row.item.dateCreated)
						: '-'
					}}</template>


					<template v-slot:cell(actions)="row">
						<span class="text-nowrap">
							<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="success"
								@click.stop="row.toggleDetails" class="mr-1">
								<i class="fa fa-eye-slash" v-if="row.detailsShowing"></i>
								<i class="fa fa-eye" v-else></i>
							</b-button>
						</span>
					</template>

					<template slot="row-details" slot-scope="row">
						<IncidentReportDetailsView :row="row" />
					</template>
				</b-table>

				<b-row>
					<b-col md="8" sm="12" class="my-1">
						<span class="totalDisplay">Total: {{ totalRows }}</span>
					</b-col>
					<b-col md="4" sm="12" class="my-1">
						<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
							class="my-0" />
					</b-col>
				</b-row>

			</b-container>
		</b-card>

		<!-- Modals here -->
		<IncidentReportLocationView />

	</div>
</template>

<script>
// Components
import IncidentReportDetailsView from '@/views/reports/incidentReport/IncidentReportDetailsView';
import IncidentReportLocationView from '@/views/reports/incidentReport/IncidentReportLocationView';

// Util
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { ValidationUtil } from '@/utils/validationUtil';
import { DateUtil } from '@/utils/dateutil';

// API
import reportApi from '@/api/reportApi';

// Others
import JsonExcel from 'vue-json-excel';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import moment from 'moment';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'incident-report',
	components: {
		IncidentReportDetailsView,
		IncidentReportLocationView,
		JsonExcel,
		Loading
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'Dispatch ID',
					sortable: true,
				},
				{
					key: 'Incident ID',
					sortable: false,
				},
				{
					key: 'Reporter',
					sortable: true,
				},
				{
					key: 'Incident Type',
					sortable: true,
				},
				{
					key: 'Date Created',
					sortable: true,
				},
				{
					key: 'Actions',
					sortable: true,
				},
			],

			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			defaultFilterBy: {
				dateFrom: moment().format('YYYY-MM-DD'),
				dateTo: moment().format('YYYY-MM-DD'),
				incidentType: config.incidentTypeDefaultValue,
				driver: config.driverDefaultValue,
				dispatchId: '',
				incidentId: '',
			},

			filterBy: {
				dateFrom: moment().format('YYYY-MM-DD'),
				dateTo: moment().format('YYYY-MM-DD'),
				incidentType: config.incidentTypeDefaultValue,
				driver: config.driverDefaultValue,
				dispatchId: '',
				incidentId: '',
			},

			prevFilterBy: {},
			params: {},

			allIncidentTypeObj: {},
			allDriversObj: {},
			allIncidentReportsObj: {},

			incidentTypeOptions: [],
			driverOptions: [],

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,

			isLoading: false,
		};
	},
	computed: {
		exportData() {
			return this.items;
		},

		exportFields() {
			return {
				'Dispatch ID': 'dispatchId',
				'Incident ID': 'incidentId',
				'Reporter': 'driver.name',
				'Plate No.': 'transportation.plateNo',
				'Summary': 'summary',
				'Latitude': 'latitude',
				'Longitude': 'longitude',
				'Incident Type': 'incidentType.name',
				'Remarks': 'remarks',
				'Date Created': 'Date Created',
			};
		},

		fileName() {
			let currTimeStamp = DateUtil.getCurrentTimestamp();
			return 'Incident-Report-' + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp);
		},
	},
	async mounted() {

		// Don't initiate data retrieval when the account is not authenticated
		if (!this.$store.getters.isAuthenticated) {
			return;
		}

		// show loading indicator
		this.isLoading = true;

		setTimeout(async () => {

			try {
				this.allIncidentTypeObj = this.$store.getters.incidentTypes;
				this.allDriversObj = this.$store.getters.driverUsers;

				this.incidentTypeOptions = DropDownItemsUtil.retriveIncidentTypeItems(this.allIncidentTypeObj, false);

				if (this.isSuperAdmin) {
					this.driverOptions = DropDownItemsUtil.retrieveActiveDriverItems(this.allDriversObj, false);
				} else {
					this.driverOptions = DropDownItemsUtil.retrieveActiveDriverItemsByCompany(this.allDriversObj, this.loggedUserCompany);
				}

				await this.retrieveData();
			} catch (error) {
				this.$toaster.error(
					'Error loading data. Please reload the page again.'
				);
			}

			// hide loading indicator
			this.isLoading = false;

		}, config.timeout);
	},
	methods: {

		dateFromDisabled(_ymd, date) {
			return date > new Date();
		},

		validateFilter() {
			let isValid = true;

			if (this.filterBy.dateFrom > this.filterBy.dateTo) {
				this.$toaster.warning(
					'Invalid Date Range. Date From must be less than Date To.'
				);
				isValid = false;
			} else if (!ValidationUtil.isAlphaNumeric(this.filterBy.dispatchId)) {
				this.$toaster.warning(
					'Invalid Dispatch ID. Please enter a valid Dispatch ID'
				);
				isValid = false;
			}

			return isValid;
		},

		onFilterRequest() {
			if (!this.validateFilter()) {
				return;
			}

			if (!_.isEqual(this.filterBy, this.prevFilter)) {
				this.retrieveData();
				this.prevFilter = Object.assign({}, this.filterBy);
			}
		},

		resetFilters() {
			if (!_.isEqual(this.filterBy, this.defaultFilterBy)) {
				// reset to default
				this.filterBy = Object.assign({}, this.defaultFilterBy);
				this.retrieveData();
				this.prevFilter = Object.assign({}, this.filterBy);
			}
		},

		async retrieveData() {
			try {
				// show loading indicator
				this.isLoading = true;

				let filter = Object.assign({}, this.filterBy);
				filter.fromTimestamp = DateUtil.startDateTimeStamp(new Date(filter.dateFrom));
				filter.toTimestamp = DateUtil.endDateTimeStamp(new Date(filter.dateTo));

				let view = this.isSuperAdmin ? config.view.ADMIN : config.view.COMPANY;

				filter.companyId = this.loggedUserCompany.id;
				let { data } = await reportApi.getIncidentReport(
					filter,
					view,
					this.loggedUser.id
				);

				this.allIncidentReportsObj = data.incidentReports;
				this.processIncidentReports(this.allIncidentReportsObj);

			} catch (error) {
				this.$toaster.error(
					'Error loading data. Please reload the page again.'
				);
			}

			// hide loading indicator
			this.isLoading = false;
		},

		processIncidentReports(incidentReports) {

			this.allIncidentReportsObj = incidentReports;

			let filteredObjs = Object.assign({}, this.allIncidentReportsObj);
			_.forEach(this.allIncidentReportsObj, (incidentReport, id) => {

				let dispatchId = this.filterBy.dispatchId;
				if (
					dispatchId &&
					dispatchId.length > 0 &&
					dispatchId !== incidentReport.dispatchId
				) {
					delete filteredObjs[id];
				}

				let incidentId = this.filterBy.incidentId;
				if (
					incidentId &&
					incidentId.length > 0 &&
					incidentId !== incidentReport.incidentId
				) {
					delete filteredObjs[id];
				}

				let incidentType = this.filterBy.incidentType;
				if (
					incidentType &&
					incidentType.id &&
					incidentType.id.length > 0 &&
					incidentType.name !== incidentReport.incidentType.name
				) {
					delete filteredObjs[id];
				}

				let driver = this.filterBy.driver;
				if (
					driver &&
					driver.id &&
					driver.id !== incidentReport.driver.userId
				) {
					delete filteredObjs[id];
				}

			});

			this.items = Object.values(filteredObjs);
			this.items.forEach((item) => {
				item['Dispatch ID'] = item.dispatchId;
				item['Incident ID'] = item.incidentId;
				item['Reporter'] = item.driver.name;
				item['Incident Type'] = item.incidentType.name;
				item['Date Created'] = this.getDisplayDateTime(item.dateCreated);
			});
			this.items = _.sortBy(this.items, ['Dispatch ID']);
			this.totalRows = this.items.length;
		},

		// UTILS
		getDisplayDateTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
	}
};
</script>