<template>
    <b-card>
        <b-tabs content-class="mt-3">
            <b-tab title="Primary Details" active>
                <b-card-text>

                    <b-form-group label="Incident ID:" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        <em> {{ row.item.incidentId }}
                        </em>
                    </b-form-group>

                    <b-form-group label="Type:" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        <em> {{ row.item.incidentType.name }}
                        </em>
                    </b-form-group>

                    <b-form-group label="Summary:" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        <em> {{ row.item.summary }}
                        </em>
                    </b-form-group>

                    <b-form-group label="Dispatch ID:" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        <em> {{ row.item.dispatchId }}
                        </em>
                    </b-form-group>

                    <b-form-group label="Source:" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        <em> {{ sourceCompany }}
                        </em>
                    </b-form-group>

                    <b-form-group label="Destination:" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        <em>
                            {{ destinationCompany }}
                        </em>
                        &nbsp;&nbsp;
                    </b-form-group>

                    <b-form-group label="Location:" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        <strong>
                            <b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Map Location'"
                                @click.stop="showDispatchLocation(row.item)" variant="success" class="mr-1">
                                <em class="fa fa-map-marker fa-lg"></em>
                            </b-button>
                        </strong>
                        {{ getCoordinates(row.item) }}
                    </b-form-group>

                    <b-form-group label="Driver:" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        {{ row.item.driver.name }}
                    </b-form-group>

                    <b-form-group label="Plate No/CR/OR:" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        {{ row.item.transportation.plateNo }}
                    </b-form-group>

                    <b-form-group label="Date Created:" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        {{ showFormattedDate(row.item.dateCreated) }}
                    </b-form-group>
                </b-card-text>
            </b-tab>

            <b-tab title="Images" active>
                <b-card-text>

                    <b-form-group label="Images:" label-cols-sm="12" label-class="font-weight-bold pt-0"
                        label-align-sm="left">
                        <b-row>
                            <b-col lg="4" md="6" sm="12" :key="index" class="mb-2" v-for="(image, index) in row.item.images">
                                <div v-show="hasMedia(image)">
                                    <b-img v-img-orientation-changer :src="image.url" alt="Responsive image" thumbnail
                                        fluid />
                                    <a href="#" class="pull-left" @click.prevent="showImage(image.url)">
                                        Click to view full image
                                    </a>
                                </div>
                            </b-col>
                        </b-row>
                    </b-form-group>

                    <b-form-group label="Remarks:" label-class="font-weight-bold pt-0" label-align-sm="left">
                        {{ row.item.remarks }}
                    </b-form-group>

                </b-card-text>
            </b-tab>
        </b-tabs>

        <b-row class="mt-4 mb-2">
            <b-col sm="12" class="text-sm-right">
                <b-button size="sm" @click="row.toggleDetails" variant="secondary" v-b-tooltip.hover.top="'Hide Details'">
                    <em class="icon-arrow-up"></em>
                </b-button>
            </b-col>
        </b-row>

        <!-- Modal -->
        <InvalidImageDialog />

    </b-card>
</template>

<script>
// Component
import InvalidImageDialog from '../../transactions/common/ImageViewDialog.vue';

// Util
import { DateUtil } from '@/utils/dateutil';
import { FileUtil } from '@/utils/fileUtil';
import { LocationUtil } from '@/utils/locationUtil';
import { DispatchUtil } from '@/utils/dispatchUtil';

// Others
import EventBus from '@/shared/event-bus';
import _ from 'lodash';

export default {
    name: 'dispatch-alert-details-view',
    props: {
        row: {
            type: Object,
            required: true,
        },
    },

    components: {
        InvalidImageDialog,
    },

    computed: {
        sourceCompany() {
            return DispatchUtil.getCompanyLocationDisplay(this.row.item.source);
        },
        destinationCompany() {
            return DispatchUtil.getCompanyLocationDisplay(this.row.item.destination);
        },
    },

    methods: {

        getCoordinates(details) {
            const { geoaddress, latitude, longitude } = details;

            const finalGeoaddress = geoaddress || {
                _latitude: latitude,
                _longitude: longitude
            };

            const formattedGeoaddress = LocationUtil.getGeoaddress(finalGeoaddress);
            const { latitude: lat, longitude: lng } = formattedGeoaddress;

            return `(${lat.toFixed(6)}, ${lng.toFixed(6)})`;
        },

        showFormattedDate(date) {
            return DateUtil.getFormattedDateWithTime(date);
        },

        getDisplayValue(value) {
            if (!value || value.length === 0) {
                return '-';
            }
            return value;
        },

        showDispatchLocation(details) {
            const geoaddress = details.geoaddress || {
                _latitude: details.latitude,
                _longitude: details.longitude,
            };

            const params = {
                source: details.source,
                destination: details.destination,
                courierInfo: {
                    driver: details.driver.name,
                    plateNo: details.transportation.plateNo,
                    geoaddress: geoaddress,
                },
            };

            EventBus.$emit('onUpdateSelIncidentReportLocationView', params);
            this.$bvModal.show('incident-report-location-view');
        },

        showImage(url) {
            let fileName = FileUtil.getUrlFileName(url);
            EventBus.$emit('onSelectImageView', {
                url: url,
                name: fileName
            });
            this.$bvModal.show('image-view-dialog');
        },

        hasMedia(media) {
            return media && media.url && !_.isEmpty(media.url);
        },
    },
};
</script>

<style scoped></style>



